import { MetaData, AreaPage } from '@/components'
import {
  fetchAreaInfo,
  fetchPartnerPromotions,
  getAreaInfoById,
} from '@/store/slices/stagesSlice/stagesSlice'
import CustomError from '@/helpers/CustomError'
import { initializeStore } from '@/store'
import { NextPage } from 'next'

const NextAreaPage: NextPage<{ id: string }> = ({ id }) => {
  return (
    <>
      <MetaData />
      <AreaPage id={id} />
    </>
  )
}

NextAreaPage.getInitialProps = async ({ query }) => {
  const areaId: string = query.areaId as string
  const reduxStore = initializeStore()
  const { dispatch, getState } = reduxStore

  await dispatch(fetchAreaInfo({ id: areaId }))
  await dispatch(fetchPartnerPromotions())

  const response = getAreaInfoById(getState(), areaId)
  if (response.error) throw new CustomError('', response.error.statusCode)

  return {
    initialReduxState: reduxStore.getState(),
    headerLayout: 'empty',
    id: areaId,
  }
}

export default NextAreaPage
